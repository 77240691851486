<script setup lang="ts">
import { useDebounceFn, useElementBounding, useMouseInElement, useWindowSize } from '@vueuse/core';

defineProps<{
  message?: string;
}>();

const isHovering = ref(false);

const trigger = ref<HTMLElement | null>(null);
const { width: windowWidth } = useWindowSize();
const { top, left, width, height, update } = useElementBounding(trigger);
const { isOutside } = useMouseInElement(trigger);

const debouncedIsOutside = useDebounceFn(() => {
  if (!isOutside.value) {
    isHovering.value = true;
  }
}, 800);

watch(isOutside, () => {
  if (!isOutside.value) {
    update();
    debouncedIsOutside();
  } else {
    update();
    isHovering.value = false;
  }
});
</script>
<template>
  <div class="relative">
    <transition
      enter-active-class="transition ease-out duration-300"
      enter-from-class="transform opacity-0 scale-y-95"
      enter-to-class="transform opacity-100 scale-y-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-y-100"
      leave-to-class="transform opacity-0 scale-y-95"
    >
      <div
        v-if="isHovering && message"
        class="fixed flex shadow-sm origin-top z-[99999] rounded-md py-1 px-2 bg-gray-800/70 items-center justify-center"
        :style="`top: ${top - height}px; right: ${windowWidth - left - width}px;`"
      >
        <p class="text-white text-xs">{{ message }}</p>
      </div>
    </transition>
    <div ref="trigger">
      <slot />
    </div>
  </div>
</template>
