<script setup lang="ts">
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { onClickOutside } from '@vueuse/core';

// Plugins
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

// Types
export type ruleType = 'disabled-days' | 'disabled-days-after';
export type Rule = { type: ruleType; value: Date[]; message?: string; color?: string };

// Composables
const { locale, t } = useI18n();

// Props
const emit = defineEmits<{
  (e: 'select-day', day: string): void;
}>();
const dateValue = defineModel<string>();
const props = defineProps<{
  customWeekDay?: boolean;
  rules?: Rule[];
}>();

// Data
const weekDays = siteStore().week;
const showYearModal = ref(false);
const date = ref<string>();
const yearModal = ref<HTMLElement>(null);
const modalDate = ref<string>();
const hoveredWeek = ref<number | null>(null);

// Hooks
onClickOutside(yearModal, () => {
  showYearModal.value = false;
});

// Computed
const weekStartDay = computed(
  () => weekDays[props.customWeekDay ? siteStore().getSite(globalStore().getSelectedSite[0]).production_start_weekday : 'monday'],
);
const getDateTitle = computed(() => {
  return `${dayjs(date.value).locale(locale.value).format('MMMM')} ${dayjs(date.value).year()}`;
});

const getDays = computed(() => {
  const currentDate = dayjs(date.value);
  const startOfMonth = currentDate.startOf('month');
  const endOfMonth = currentDate.endOf('month');
  const days = [];

  // Get the day of the week of the first day of the month (0-6)
  let firstDayOfWeek = startOfMonth.day();

  // Adjust according to the start of the week
  if (weekStartDay.value > 0) {
    firstDayOfWeek = firstDayOfWeek - (weekStartDay.value % 7);
    if (firstDayOfWeek < 0) firstDayOfWeek += 7;
  }

  // Add the days of the previous month
  const prevMonth = startOfMonth.subtract(1, 'month');
  const daysInPrevMonth = prevMonth.daysInMonth();
  for (let i = 0; i < firstDayOfWeek; i++) {
    const dayNumber = daysInPrevMonth - firstDayOfWeek + i + 1;
    days.push({
      month: 'prev',
      dayNumber,
      date: prevMonth.date(dayNumber).toDate(),
    });
  }

  // Add the days of the current month
  for (let day = 1; day <= endOfMonth.date(); day++) {
    days.push({
      month: 'current',
      dayNumber: day,
      date: currentDate.date(day).toDate(),
    });
  }

  // Ajouter les jours du mois suivant
  const nextMonth = endOfMonth.add(1, 'month');
  const remainingDays = 42 - days.length;
  for (let day = 1; day <= remainingDays; day++) {
    days.push({
      month: 'next',
      dayNumber: day,
      date: nextMonth.date(day).toDate(),
    });
  }

  return days;
});

// Ajout du computed pour obtenir les semaines
const getWeeks = computed(() => {
  const days = getDays.value;
  const weeks: (typeof days)[] = [];

  for (let i = 0; i < days.length; i += 7) {
    weeks.push(days.slice(i, i + 7));
  }

  return weeks;
});

// Ajout du computed pour obtenir la semaine sélectionnée
const selectedWeekIndex = computed(() => {
  if (!dateValue.value) return null;

  const selectedDate = dayjs(dateValue.value);
  return getWeeks.value.findIndex((week) => week.some((day) => dayjs(day.date).isSame(selectedDate, 'day')));
});

// Methods
const slideDirection = ref('right');

const changeMonth = (value: number) => {
  slideDirection.value = value > 0 ? 'left' : 'right';
  if (value === 1) {
    date.value = dayjs(date.value).add(1, 'month').date(1).format('YYYY-MM-DD');
  } else if (value === -1) {
    date.value = dayjs(date.value).subtract(1, 'month').date(1).format('YYYY-MM-DD');
  }
};

const getDaysInWeek = () => {
  const startDay = weekStartDay.value;
  const days = [];
  for (let i = 0; i < 7; i++) {
    const currentDay = (startDay + i) % 7;
    days.push(t(`days.${Object.entries(weekDays)[currentDay][0].toLowerCase()}`));
  }
  return days;
};

const isDisabledMonth = (monthIndex: number) => {
  if (!props.rules) return false;

  const currentDate = dayjs(modalDate.value).month(monthIndex - 1);
  const daysInMonth = currentDate.daysInMonth();

  let disabledDaysCount = 0;

  for (let day = 1; day <= daysInMonth; day++) {
    const dayDate = currentDate.date(day);
    const isDisabled = props.rules.some((rule) => {
      switch (rule.type) {
        case 'disabled-days':
          return rule.value.some((disabledDate) => dayDate.isSame(dayjs(disabledDate), 'day'));

        case 'disabled-days-after':
          return dayDate.isAfter(rule.value[0], 'day');

        default:
          return false;
      }
    });

    if (isDisabled) disabledDaysCount++;
  }

  return disabledDaysCount === daysInMonth;
};

const changeYear = (value: number) => {
  if (value === 1) {
    modalDate.value = dayjs(modalDate.value).add(1, 'year').date(1).format('YYYY-MM-DD');
  } else if (value === -1) {
    modalDate.value = dayjs(modalDate.value).subtract(1, 'year').date(1).format('YYYY-MM-DD');
  }
};

const selectMonth = (monthIndex: number) => {
  if (isDisabledMonth(monthIndex)) return;
  date.value = dayjs(modalDate.value)
    .month(monthIndex - 1)
    .date(1)
    .format('YYYY-MM-DD');
  showYearModal.value = false;
};

const getWeekNumber = (date: Date) => {
  return dayjs(date).week();
};

// Ajout de la fonction pour vérifier si une semaine est désactivée
const isDisabledWeek = (week: typeof getDays.value): { isDisabled: boolean; message: string; color?: string } => {
  if (!props.rules) return { isDisabled: false, message: '', color: undefined };

  // Vérifie d'abord si la semaine contient des jours désactivés par disabled-days
  const disabledDaysRule = props.rules.find((rule) => rule.type === 'disabled-days');
  if (disabledDaysRule) {
    const hasDisabledDay = week.some((day) => {
      return disabledDaysRule.value.some((disabledDate) => dayjs(day.date).isSame(dayjs(disabledDate), 'day'));
    });
    if (hasDisabledDay) {
      return { isDisabled: true, message: disabledDaysRule.message || '', color: disabledDaysRule.color };
    }
  }

  // Vérifie ensuite si la semaine contient des jours après la date limite
  const disabledDayAfterRule = props.rules.find((rule) => rule.type === 'disabled-days-after');
  if (disabledDayAfterRule) {
    const hasDisabledDays = week.some((day) => dayjs(day.date).isAfter(disabledDayAfterRule.value[0], 'day'));
    if (hasDisabledDays) {
      return { isDisabled: true, message: disabledDayAfterRule.message || '', color: disabledDayAfterRule.color };
    }
  }

  return { isDisabled: false, message: '', color: undefined };
};

const selectWeek = (week: typeof getDays.value) => {
  const weekDisabled = isDisabledWeek(week);
  if (weekDisabled.isDisabled) return;

  const firstDay = week[0];
  const weekStart = dayjs(firstDay.date).format('YYYY-MM-DD');
  date.value = weekStart;
  dateValue.value = dayjs(weekStart).format('YYYY-MM-DD');
  emit('select-day', dayjs(weekStart).format('YYYY-MM-DD'));
};

// Lifecycle
watch(
  () => dateValue.value,
  (value) => {
    date.value = value ? dayjs(value).date(1).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    modalDate.value = date.value;
  },
  { immediate: true },
);

watch(
  () => showYearModal.value,
  (value) => {
    if (value) {
      modalDate.value = date.value;
    }
  },
);
</script>
<template>
  <div class="border relative border-gray-200 bg-white rounded-md p-2">
    <!-- Month selecor -->
    <div class="flex justify-between items-center mb-2">
      <div
        class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center hover:bg-gray-50"
        @click="changeMonth(-1)"
      >
        <ui-icon name="ChevronLeft" class="w-4 h-4" />
      </div>
      <div
        class="text-gray-900 font-semibold text-sm capitalize select-none cursor-pointer hover:bg-gray-50 rounded-md p-1 px-2"
        @click="showYearModal = true"
      >
        {{ getDateTitle }}
      </div>
      <div
        class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center hover:bg-gray-50"
        @click="changeMonth(1)"
      >
        <ui-icon name="ChevronRight" class="w-4 h-4" />
      </div>
    </div>

    <!-- Day of week -->
    <div class="grid grid-cols-8 text-center text-gray-500 text-sm uppercase select-none">
      <div class="w-8 h-8"></div>
      <div v-for="day in getDaysInWeek()" :key="day" class="w-8 h-8 font-semibold">{{ day[0] }}</div>
    </div>

    <!-- Week list -->
    <div class="overflow-hidden">
      <Transition :name="slideDirection === 'right' ? 'slide-right' : 'slide-left'" mode="out-in">
        <div :key="dayjs(date).format('YYYY-MM')" class="flex justify-start items-start flex-col">
          <div
            v-for="(week, weekIndex) in getWeeks"
            :key="weekIndex"
            class="group"
            @mouseenter="hoveredWeek = weekIndex"
            @mouseleave="hoveredWeek = null"
            @click="selectWeek(week)"
          >
            <ui-tooltip :message="isDisabledWeek(week).message">
              <div class="relative grid grid-cols-8 h-8">
                <div
                  class="text-xs flex justify-center select-none items-center font-semibold border-r border-gray-200 w-8 h-8 rounded-l-full"
                  :class="[
                    {
                      'text-primary-500': selectedWeekIndex === weekIndex && !isDisabledWeek(week).isDisabled,
                      'group-hover:bg-gray-50 group-hover:text-gray-900':
                        selectedWeekIndex !== weekIndex && !isDisabledWeek(week).isDisabled,
                      'cursor-not-allowed !bg-white': isDisabledWeek(week).isDisabled,
                    },
                    isDisabledWeek(week).color || 'text-gray-300',
                  ]"
                >
                  S{{ getWeekNumber(week[0].date) }}
                </div>
                <div
                  v-for="day in week"
                  :key="`${dayjs(day.date).format('YYYY-MM-DD')}`"
                  class="flex justify-center select-none items-center text-xs w-8 h-8 last:rounded-r-full"
                  :class="[
                    {
                      'text-gray-800': day.month === 'current' && !isDisabledWeek(week).isDisabled,
                      'text-gray-400': (day.month === 'prev' || day.month === 'next') && !isDisabledWeek(week).isDisabled,
                      'group-hover:bg-gray-50 cursor-pointer': selectedWeekIndex !== weekIndex && !isDisabledWeek(week).isDisabled,
                      'text-white bg-primary-500': selectedWeekIndex === weekIndex && !isDisabledWeek(week).isDisabled,
                      'cursor-not-allowed': isDisabledWeek(week).isDisabled,
                    },
                    isDisabledWeek(week).color || 'text-gray-300',
                  ]"
                >
                  {{ day.dayNumber }}
                </div>
              </div></ui-tooltip
            >
          </div>
        </div>
      </Transition>
    </div>

    <!-- Month modal -->
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 -translate-y-5"
      enter-to-class="transform opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="transform opacity-100 translate-y-0"
      leave-to-class="transform opacity-0 -translate-y-5"
    >
      <div v-if="showYearModal" class="absolute top-0 left-0 right-0 bottom-0 z-50 rounded">
        <div ref="yearModal" class="absolute top-[8px] left-[8px] right-[8px] bg-white shadow-md border border-gray-200 rounded p-3">
          <!-- Year selector -->
          <div class="flex justify-between items-center mb-2">
            <div class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center" @click="changeYear(-1)">
              <ui-icon name="ChevronLeft" class="w-4 h-4" />
            </div>
            <div class="text-gray-900 font-semibold text-sm capitalize select-none">
              {{ dayjs(modalDate).locale(locale).format('YYYY') }}
            </div>
            <div class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center" @click="changeYear(1)">
              <ui-icon name="ChevronRight" class="w-4 h-4" />
            </div>
          </div>

          <!-- Month list -->
          <div class="grid grid-cols-4 gap-2">
            <div
              v-for="month in 12"
              :key="month"
              class="text-center text-sm select-none h-8 w-12 flex justify-center items-center rounded-md cursor-pointer"
              :class="{
                'text-gray-800 hover:bg-gray-100': !isDisabledMonth(month),
                'text-gray-300 cursor-not-allowed': isDisabledMonth(month),
                'bg-primary-500 text-white hover:bg-primary-600':
                  dayjs(date).month() === month - 1 && dayjs(date).year() === dayjs(modalDate).year(),
              }"
              @click="selectMonth(month)"
            >
              {{ $t(`global.months.${month}`).slice(0, 3) }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.1s ease-out;
  position: relative;
}

.slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

.slide-left-enter-from {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

/* Ajout des styles pour la bordure droite des numéros de semaine */
.week-number-border {
  position: absolute;
  left: -14px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #e5e7eb; /* gray-200 */
}
</style>
