<script setup lang="ts">
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { onClickOutside } from '@vueuse/core';

// Plugins
dayjs.extend(weekday);

// Types
export type ruleType = 'disabled-days' | 'disabled-days-after';
export type Rule = { type: ruleType; value: Date[]; message?: string; color?: string };

// Composables
const { locale, t } = useI18n();

// Props
const emit = defineEmits<{
  (e: 'select-day', day: string): void;
}>();
const dateValue = defineModel<string>();
const props = defineProps<{
  customWeekDay?: boolean;
  rules?: Rule[];
}>();

// Data
const weekDays = siteStore().week;
const showYearModal = ref(false);
const date = ref<string>();
const yearModal = ref<HTMLElement>(null);
const modalDate = ref<string>();

// Hooks
onClickOutside(yearModal, () => {
  showYearModal.value = false;
});

// Computed
const weekStartDay = computed(
  () => weekDays[props.customWeekDay ? siteStore().getSite(globalStore().getSelectedSite[0]).production_start_weekday : 'monday'],
);
const getDateTitle = computed(() => {
  return `${dayjs(date.value).locale(locale.value).format('MMMM')} ${dayjs(date.value).year()}`;
});

const getDays = computed(() => {
  const currentDate = dayjs(date.value);
  const startOfMonth = currentDate.startOf('month');
  const endOfMonth = currentDate.endOf('month');
  const days = [];

  // Get the day of the week of the first day of the month (0-6)
  let firstDayOfWeek = startOfMonth.day();

  // Adjust according to the start of the week
  if (weekStartDay.value > 0) {
    firstDayOfWeek = firstDayOfWeek - (weekStartDay.value % 7);
    if (firstDayOfWeek < 0) firstDayOfWeek += 7;
  }

  // Add the days of the previous month
  const prevMonth = startOfMonth.subtract(1, 'month');
  const daysInPrevMonth = prevMonth.daysInMonth();
  for (let i = 0; i < firstDayOfWeek; i++) {
    const dayNumber = daysInPrevMonth - firstDayOfWeek + i + 1;
    days.push({
      month: 'prev',
      dayNumber,
      date: prevMonth.date(dayNumber).toDate(),
    });
  }

  // Add the days of the current month
  for (let day = 1; day <= endOfMonth.date(); day++) {
    days.push({
      month: 'current',
      dayNumber: day,
      date: currentDate.date(day).toDate(),
    });
  }

  // Ajouter les jours du mois suivant
  const nextMonth = endOfMonth.add(1, 'month');
  const remainingDays = 42 - days.length;
  for (let day = 1; day <= remainingDays; day++) {
    days.push({
      month: 'next',
      dayNumber: day,
      date: nextMonth.date(day).toDate(),
    });
  }

  return days;
});

// Methods
const slideDirection = ref('right');

const changeMonth = (value: number) => {
  slideDirection.value = value > 0 ? 'left' : 'right';
  if (value === 1) {
    date.value = dayjs(date.value).add(1, 'month').date(1).format('YYYY-MM-DD');
  } else if (value === -1) {
    date.value = dayjs(date.value).subtract(1, 'month').date(1).format('YYYY-MM-DD');
  }
};

const getDaysInWeek = () => {
  const startDay = weekStartDay.value;
  const days = [];
  for (let i = 0; i < 7; i++) {
    const currentDay = (startDay + i) % 7;
    days.push(t(`days.${Object.entries(weekDays)[currentDay][0].toLowerCase()}`));
  }
  return days;
};

const isDisabledMonth = (monthIndex: number) => {
  if (!props.rules) return false;

  const currentDate = dayjs(modalDate.value).month(monthIndex - 1);
  const daysInMonth = currentDate.daysInMonth();

  let disabledDaysCount = 0;

  for (let day = 1; day <= daysInMonth; day++) {
    const dayDate = currentDate.date(day);
    const isDisabled = props.rules.some((rule) => {
      switch (rule.type) {
        case 'disabled-days':
          return rule.value.some((disabledDate) => dayDate.isSame(dayjs(disabledDate), 'day'));

        case 'disabled-days-after':
          return dayDate.isAfter(rule.value[0], 'day');

        default:
          return false;
      }
    });

    if (isDisabled) disabledDaysCount++;
  }

  return disabledDaysCount === daysInMonth;
};

const isDisabledDay = (day: { month: string; dayNumber: number }): { isDisabled: boolean; message: string; color?: string } => {
  if (!props.rules) return { isDisabled: false, message: '', color: undefined };

  let currentDate;
  if (day.month === 'prev') {
    currentDate = dayjs(date.value).subtract(1, 'month').date(day.dayNumber);
  } else if (day.month === 'next') {
    currentDate = dayjs(date.value).add(1, 'month').date(day.dayNumber);
  } else {
    currentDate = dayjs(date.value).date(day.dayNumber);
  }

  const result = props.rules.find((rule) => {
    switch (rule.type) {
      case 'disabled-days':
        return rule.value.some((disabledDate) => currentDate.isSame(dayjs(disabledDate), 'day'));
      case 'disabled-days-after':
        return currentDate.isAfter(rule.value[0], 'day');
      default:
        return false;
    }
  });

  return { isDisabled: !!result, message: result?.message || '', color: result?.color };
};

const selectDay = (day: { month: string; dayNumber: number; date: string }) => {
  const disabled = isDisabledDay(day);
  if (disabled.isDisabled) return;

  date.value = dayjs(day.date).format('YYYY-MM-DD');
  dateValue.value = day.date;
  emit('select-day', date.value);
};

const changeYear = (value: number) => {
  if (value === 1) {
    modalDate.value = dayjs(modalDate.value).add(1, 'year').date(1).format('YYYY-MM-DD');
  } else if (value === -1) {
    modalDate.value = dayjs(modalDate.value).subtract(1, 'year').date(1).format('YYYY-MM-DD');
  }
};

const selectMonth = (monthIndex: number) => {
  if (isDisabledMonth(monthIndex)) return;
  date.value = dayjs(modalDate.value)
    .month(monthIndex - 1)
    .date(1)
    .format('YYYY-MM-DD');
  showYearModal.value = false;
};

// Lifecycle
watch(
  () => dateValue.value,
  (value) => {
    date.value = value ? dayjs(value).date(1).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    modalDate.value = date.value;
  },
  { immediate: true },
);

watch(
  () => showYearModal.value,
  (value) => {
    if (value) {
      modalDate.value = date.value;
    }
  },
);
</script>
<template>
  <div class="border relative border-gray-200 bg-white rounded-md p-2">
    <!-- Month selecor -->
    <div class="flex justify-between items-center mb-2">
      <div
        class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center hover:bg-gray-50"
        @click="changeMonth(-1)"
      >
        <ui-icon name="ChevronLeft" class="w-4 h-4" />
      </div>
      <div
        class="text-gray-900 font-semibold text-sm capitalize select-none cursor-pointer hover:bg-gray-50 rounded-md p-1 px-2"
        @click="showYearModal = true"
      >
        {{ getDateTitle }}
      </div>
      <div
        class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center hover:bg-gray-50"
        @click="changeMonth(1)"
      >
        <ui-icon name="ChevronRight" class="w-4 h-4" />
      </div>
    </div>

    <!-- Day of week -->
    <div class="grid grid-cols-7 text-center text-gray-500 text-sm uppercase select-none">
      <div v-for="day in getDaysInWeek()" :key="day" class="w-8 h-8 font-semibold">{{ day[0] }}</div>
    </div>

    <!-- Day list -->
    <div class="overflow-hidden">
      <Transition :name="slideDirection === 'right' ? 'slide-right' : 'slide-left'" mode="out-in">
        <div :key="dayjs(date).format('YYYY-MM')" class="grid grid-cols-7">
          <div v-for="day in getDays" :key="`${dayjs(day.date).format('YYYY-MM-DD')}`" @click="selectDay(day)">
            <ui-tooltip :message="isDisabledDay(day).message">
              <div
                class="text-center text-xs p-1 py-2 h-8 w-8 rounded-full select-none"
                :class="[
                  {
                    'text-gray-800 hover:bg-gray-100 cursor-pointer': day.month === 'current' && !isDisabledDay(day).isDisabled,
                    'text-gray-500 hover:bg-gray-100 cursor-pointer':
                      (day.month === 'prev' || day.month === 'next') && !isDisabledDay(day).isDisabled,
                    'cursor-not-allowed': isDisabledDay(day).isDisabled,
                    'bg-primary-500 text-white hover:bg-primary-600':
                      dateValue && dayjs(dateValue).format('YYYY-MM-DD') === dayjs(day.date).format('YYYY-MM-DD'),
                  },
                  isDisabledDay(day).isDisabled ? isDisabledDay(day).color || 'text-gray-300' : '',
                ]"
              >
                {{ day.dayNumber }}
              </div>
            </ui-tooltip>
          </div>
        </div>
      </Transition>
    </div>

    <!-- Month modal -->
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 -translate-y-5"
      enter-to-class="transform opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="transform opacity-100 translate-y-0"
      leave-to-class="transform opacity-0 -translate-y-5"
    >
      <div v-if="showYearModal" class="absolute top-0 left-0 right-0 bottom-0 z-50 rounded">
        <div ref="yearModal" class="absolute top-[8px] left-[8px] right-[8px] bg-white shadow-md border border-gray-200 rounded p-3">
          <!-- Year selector -->
          <div class="flex justify-between items-center mb-2">
            <div class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center" @click="changeYear(-1)">
              <ui-icon name="ChevronLeft" class="w-4 h-4" />
            </div>
            <div class="text-gray-900 font-semibold text-sm capitalize select-none">
              {{ dayjs(modalDate).locale(locale).format('YYYY') }}
            </div>
            <div class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center" @click="changeYear(1)">
              <ui-icon name="ChevronRight" class="w-4 h-4" />
            </div>
          </div>

          <!-- Month list -->
          <div class="grid grid-cols-4 gap-2">
            <div
              v-for="month in 12"
              :key="month"
              class="text-center text-sm select-none h-8 w-12 flex justify-center items-center rounded-md cursor-pointer"
              :class="{
                'text-gray-800 hover:bg-gray-100': !isDisabledMonth(month),
                'text-gray-300 cursor-not-allowed': isDisabledMonth(month),
                'bg-primary-500 text-white hover:bg-primary-600':
                  dayjs(date).month() === month - 1 && dayjs(date).year() === dayjs(modalDate).year(),
              }"
              @click="selectMonth(month)"
            >
              {{ $t(`global.months.${month}`).slice(0, 3) }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.1s ease-out;
  position: relative;
}

.slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

.slide-left-enter-from {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}
</style>
