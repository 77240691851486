<script setup lang="ts">
import dayjs from 'dayjs';
import { useField } from 'vee-validate';
import type { Rule } from './DayCalendar.vue';
import { onClickOutside, useElementBounding } from '@vueuse/core';

// Props
const props = defineProps<{
  name: string;
  label?: string;
  required?: boolean;
  hideDetails?: boolean;
  selectionType?: 'day' | 'week' | 'month';
  customWeekDay?: boolean;
  rules?: Rule[];
  disabled?: boolean;
  disabledDateColor?: string;
  disabledDates?: { start?: Date; end?: Date; interval?: number }[];
  disabledTooltip?: string;
}>();
const { value, errorMessage } = useField<string>(props.name);

// Data
const isOpen = ref(false);
const inputContainer = ref<HTMLElement | null>(null);
const button = ref<HTMLElement | null>(null);
const { left, width: buttonWidth, height, top, y } = useElementBounding(button);
const element = ref<HTMLElement | null>(null);
const { width: elementsWidth, height: elementsHeight } = useElementBounding(element);

// Hooks
onClickOutside(element, () => (isOpen.value = false));

// Computed
const getWindowHeight = computed(() => {
  return window.innerHeight;
});

const getYPosition = computed(() => {
  if (getWindowHeight.value - y.value > elementsHeight.value + 32) {
    return `top: ${top.value + height.value + 8}px;`;
  } else {
    return `top: ${y.value - elementsHeight.value - 8}px;`;
  }
});

// Methods
const openCalendar = () => {
  if (props.disabled) return;
  if (isOpen.value) return;
  isOpen.value = true;
};
</script>
<template>
  <div ref="inputContainer" class="w-full relative" :class="[hideDetails ? '' : 'mb-[16px]']">
    <label v-if="label" :class="errorMessage ? 'text-red-300' : 'text-gray-600'" class="font-medium text-xs mb-[6px] block text-left">
      {{ label }}
      <span v-if="required" class="text-red-500">*</span>
    </label>

    <div class="w-full relative">
      <div
        ref="button"
        :class="[
          errorMessage ? 'border-red-600' : 'border-gray-200  hover:ring-[1px]',
          isOpen
            ? errorMessage
              ? 'border-red-600 ring-red-100 ring-[2px]'
              : 'border-primary-500 ring-green-100 ring-[2px]'
            : errorMessage
              ? ''
              : 'hover:border-gray-300 hover:ring-gray-100',
          disabled ? '!border-gray-100' : '',
        ]"
        class="flex select-none z-0 h-[32px] bg-white items-center w-full cursor-pointer justify-start border rounded-md px-2 pr-5 text-sm font-medium group-hover:text-gray-700 text-gray-600"
        @click="openCalendar()"
      >
        <template v-if="value">
          {{ dayjs(value).format('DD/MM/YYYY') }}
        </template>
        <template v-else>
          <span class="text-gray-400 font-normal">
            {{ $t('global.select_a_date') }}
          </span>
        </template>
        <ui-icon class="right-2 h-4 w-4 absolute z-5 text-gray-600 group-hover:text-gray-700" name="ChevronDown" />
      </div>

      <!-- Error message -->
      <span class="absolute right-0 top-[34px] text-red-500 text-xs">
        {{ errorMessage }}
      </span>
    </div>
  </div>

  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-y-95"
    enter-to-class="transform opacity-100 scale-y-100"
    leave-active-class="transition ease-in duration-100"
    leave-from-class="transform opacity-100 scale-y-100"
    leave-to-class="transform opacity-0 scale-y-95"
  >
    <div
      v-show="isOpen"
      ref="element"
      class="fixed origin-top z-50 shadow rounded-md"
      :style="`left: ${left + buttonWidth - elementsWidth}px;` + getYPosition"
    >
      <ui-form-input-date-week-calendar
        v-if="selectionType === 'week'"
        v-model="value"
        :rules="rules"
        :custom-week-day="customWeekDay"
        @select-day="isOpen = false"
      />
      <ui-form-input-date-month-calendar
        v-else-if="selectionType === 'month'"
        v-model="value"
        :rules="rules"
        :custom-week-day="customWeekDay"
        @select-day="isOpen = false"
      />
      <ui-form-input-date-day-calendar
        v-else
        v-model="value"
        :rules="rules"
        :custom-week-day="customWeekDay"
        @select-day="isOpen = false"
      />
    </div>
  </transition>
</template>
