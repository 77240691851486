<script setup lang="ts">
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

// Plugins
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

// Types
export type ruleType = 'disabled-days' | 'disabled-days-after';
export type Rule = { type: ruleType; value: Date[]; message?: string; color?: string };

// Composables
const { locale } = useI18n();

// Props
const dateValue = defineModel<string>();
const emit = defineEmits<{
  (e: 'select-day', day: string): void;
}>();
const props = defineProps<{
  customWeekDay?: boolean;
  rules?: Rule[];
}>();

// Data
const date = ref<string>();

// Methods
const isDisabledMonth = (monthIndex: number): { isDisabled: boolean; message: string; color?: string } => {
  if (!props.rules) return { isDisabled: false, message: '', color: undefined };

  const currentDate = dayjs(date.value).month(monthIndex - 1);

  const result = props.rules.find((rule) => {
    switch (rule.type) {
      case 'disabled-days':
        // Vérifie si des jours désactivés sont dans ce mois
        return rule.value.some((disabledDate) => {
          const disabledMonth = dayjs(disabledDate);
          return disabledMonth.month() === monthIndex - 1 && disabledMonth.year() === currentDate.year();
        });

      case 'disabled-days-after':
        // Désactiver le mois seulement s'il est entièrement après la date limite
        const monthStart = currentDate.startOf('month');
        return monthStart.isAfter(rule.value[0], 'day');

      default:
        return false;
    }
  });

  return {
    isDisabled: !!result,
    message: result?.message || '',
    color: result?.color,
  };
};

const changeYear = (value: number) => {
  if (value === 1) {
    date.value = dayjs(date.value).add(1, 'year').date(1).format('YYYY-MM-DD');
  } else if (value === -1) {
    date.value = dayjs(date.value).subtract(1, 'year').date(1).format('YYYY-MM-DD');
  }
};

const selectMonth = (monthIndex: number) => {
  if (isDisabledMonth(monthIndex).isDisabled) return;
  const month = dayjs()
    .set('month', monthIndex - 1)
    .set('year', dayjs(date.value).year())
    .startOf('month')
    .format('YYYY-MM-DD');
  dateValue.value = month;
  date.value = month;
  emit('select-day', month);
};

// Lifecycle
watch(
  () => dateValue.value,
  (value) => {
    date.value = value ? dayjs(value).startOf('month').format('YYYY-MM-DD') : dayjs().startOf('month').format('YYYY-MM-DD');
  },
  { immediate: true },
);
</script>
<template>
  <div class="bg-white shadow-md border border-gray-200 rounded p-3">
    <div class="flex justify-between items-center mb-2">
      <div class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center" @click="changeYear(-1)">
        <ui-icon name="ChevronLeft" class="w-4 h-4" />
      </div>
      <div class="text-gray-900 font-semibold text-sm capitalize select-none">
        {{ dayjs(date).locale(locale).format('YYYY') }}
      </div>
      <div class="cursor-pointer text-gray-600 rounded-md h-8 w-8 flex justify-center items-center" @click="changeYear(1)">
        <ui-icon name="ChevronRight" class="w-4 h-4" />
      </div>
    </div>
    <div class="grid grid-cols-4 gap-2">
      <div
        v-for="month in 12"
        :key="month"
        class="rounded-md select-none"
        :class="[
          {
            'text-gray-800 hover:bg-gray-100 cursor-pointer': !isDisabledMonth(month).isDisabled,
            'cursor-not-allowed': isDisabledMonth(month).isDisabled,
            'bg-primary-500 text-white hover:bg-primary-600':
              dayjs(date).month() === month - 1 && dayjs(date).year() === dayjs(dateValue).year(),
          },
          isDisabledMonth(month).color || 'text-gray-300',
        ]"
        @click="selectMonth(month)"
      >
        <ui-tooltip :message="isDisabledMonth(month).message">
          <div class="text-center text-sm h-8 w-12 flex justify-center items-center">
            {{ $t(`global.months.${month}`).slice(0, 3) }}
          </div>
        </ui-tooltip>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.1s ease-out;
  position: relative;
}

.slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

.slide-left-enter-from {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

/* Ajout des styles pour la bordure droite des numéros de semaine */
.week-number-border {
  position: absolute;
  left: -14px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #e5e7eb; /* gray-200 */
}
</style>
